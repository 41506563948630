import { useEffect, useState } from 'react';

export default function useOnScreen(ref: React.RefObject<Element>) {
    if (typeof window === 'undefined') {
        return false;
    }

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
    );

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, [ref.current]);

    return isIntersecting;
}
