import { Wrapper } from '@/components/wrapper';

import '../styles/styles.scss';
import { graphql, PageProps, HeadProps } from 'gatsby';
import { Navigation } from '@/components/navigations/Navigation';
import { css } from '@emotion/react';
import { Breadcrumb } from '@/components/breadcrumb/Breadcrumb';
import { Block } from '@/components/blocks/Block';
import { CookieBanner } from '@/components/cookiebanner/CookieBanner';
import useCookie from 'hooks/useCookie';

type DataProps = {
    page: Queries.HygraphCMS_Page;
    navigation: Queries.HygraphCMS_Navigation;
};

const pageThemes = {
    white: {
        backgroundColor: 'white',
    },
    whitetobeige: {
        backgroundImage: 'var(--rr-gradient-white-beige)',
        backgroundRepeat: 'no-repeat',
    },
    beige: {
        backgroundColor: 'var(--rr-color-beige)',
    },
    turquoise: {
        backgroundColor: 'var(--rr-color-turquoise)',
    },
    dark: {
        backgroundColor: 'var(--rr-color-dark)',
    },
    green: {
        color: 'white',
        backgroundColor: 'var(--rr-color-green)',
    },
};

const DefaultPageTemplate = ({ data: { page } }: PageProps<DataProps>) => {
    const [langCookie, setLangCookie] = useCookie('preferedlang');

    if (langCookie !== page.locale) {
        setLangCookie(page.locale);
    }

    return (
        <div
            css={css({
                position: 'relative',
                minHeight: '100vh',
                ...pageThemes[page.theme],
            })}
        >
            <Navigation page={page} />
            {page.breadcrumb && <Breadcrumb {...page} />}
            <Wrapper page={page}></Wrapper>
            {page.footer && (
                <Block block={page.footer} page={page} index={-1} />
            )}
            {page.cookieBanner && (
                <CookieBanner cookieBanner={page.cookieBanner} />
            )}
        </div>
    );
};

export default DefaultPageTemplate;

export const Head = ({
    data: {
        page: { seo },
    },
}: HeadProps<DataProps>) => (
    <>
        <title>{`${seo?.title || ''} | Roughly Right`}</title>
        <meta name="description" content={seo?.description} />
        {seo?.noIndex && <meta name="robots" content="noindex"></meta>}
    </>
);

export const pageQuery = graphql`
    query PageQuery($id: String!, $locale: HygraphCMS_Locale!) {
        page: hygraphCmsPage(id: { eq: $id }, locale: { eq: $locale }) {
            id
            locale
            navigationLabel
            slug
            theme
            pageLocales {
                locale
                navigationLabel
                slug
            }
            seo {
                title
                description
                noIndex
            }
            breadcrumb {
                ...HygraphCMS_Breadcrumb
            }
            navigation {
                ...HygraphCMS_Navigation
            }
            blocks {
                ...HygraphCMS_Block
            }
            footer {
                ...HygraphCMS_Block
            }
            cookieBanner {
                ...HygraphCMS_CookieBanner
            }
        }
    }
`;
