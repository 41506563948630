import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@/blocks';
import { Container } from 'react-bootstrap';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import useOnScreen from 'hooks/useOnScreen';

const blockThemes = {
    white: {
        backgroundColor: 'white',
    },
    whitetobeige: {
        backgroundImage: 'var(--rr-gradient-white-beige)',
        backgroundRepeat: 'no-repeat',
    },
    beige: {
        backgroundColor: 'var(--rr-color-beige)',
    },
    turquoise: {
        backgroundColor: 'var(--rr-color-turquoise)',
    },
    dark: {
        color: 'white',
        backgroundColor: 'var(--rr-color-dark)',
    },
    green: {
        color: 'white',
        backgroundColor: 'var(--rr-color-green)',
    },
};

type BlockProps = {
    block: Queries.HygraphCMS_Block;
    page: Queries.HygraphCMS_Page;
    index: number;
};

export const Block = ({
    block: { theme, tag, grids = [], anchorId },
    page,
}: BlockProps) => {
    if (!grids.length) return null;

    const ref = useRef<HTMLElement>(null);
    const isVisible = useOnScreen(ref);
    const [wasVisible, setWasVisible] = useState(false);

    useEffect(() => {
        if (isVisible) {
            setWasVisible(true);
        }
    }, [isVisible]);

    return (
        <Container
            fluid
            ref={ref}
            as={tag}
            className={`d-flex flex-column align-items-center`}
            css={css({
                '>*': tag !== 'footer' && {
                    transitionProperty: 'opacity, translate',
                    transitionDuration: '0.25s',
                    transitionDelay: '0.25s',
                    transitionTimingFunction: 'ease-in-out',
                    opacity: wasVisible || isVisible ? 1 : 0,
                    translate: wasVisible || isVisible ? '0' : '0 10px',
                },
                ...(theme && blockThemes[theme] && blockThemes[theme]),
                position: 'relative',
            })}
        >
            {anchorId && (
                <div
                    id={anchorId}
                    css={css({
                        visibility: 'hidden',
                        position: 'absolute',
                        top: '50px',
                    })}
                ></div>
            )}
            {grids.map((grid) => {
                switch (grid.component) {
                    case 'Grid':
                        const asGrid = grid as Queries.HygraphCMS_Grid;
                        return (
                            <Grid key={asGrid.id} page={page} grid={asGrid} />
                        );
                    default:
                        return null;
                }
            })}
        </Container>
    );
};

export const blockFragment = graphql`
    fragment HygraphCMS_Block on HygraphCMS_Block {
        id
        component
        tag
        theme
        anchorId
        grids {
            ...HygraphCMS_Grid
        }
    }
`;
