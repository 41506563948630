import React from 'react';
import { Block } from './blocks/Block';

export const Wrapper = ({ page }: { page: Queries.HygraphCMS_Page }) => {
    return (
        <main>
            {page.blocks.map((block, index) => {
                switch (block.component) {
                    case 'Block':
                        const asBlock = block as Queries.HygraphCMS_Block;
                        return (
                            <Block
                                key={asBlock.id}
                                block={asBlock}
                                page={page}
                                index={index}
                            />
                        );
                    default:
                        return null;
                }
            })}
        </main>
    );
};
